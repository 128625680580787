<template>
  <v-text-field
    solo
    prepend-inner-icon="mdi-magnify"
    placeholder="Search"
    v-model="$store.state[store].query"
    v-on:input="search($event)"
  />
</template>

<script>
export default {
  props: ["store", "action"],
  methods: {
    search(event) {
      var vm = this;
      vm.$store.dispatch(`${vm.store}/${vm.action}`, {
        page: 0,
        query: event,
        date: vm.$store.state[vm.store].date,
        count: vm.$store.state[vm.store].count,
        status: vm.$store.state[vm.store].status,
      });
    },
  },
};
</script>